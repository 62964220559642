import { Formik } from 'formik';
import { useContext } from 'react';
import * as Yup from 'yup';
import { t } from '../../generated/i18n';
import { executeGQL } from '../../lib/api';
import { trackEvent } from '../../lib/tracking';
import { Button } from '../common/button';
import { ToastContext } from '../toast/toast-context';
import { CheckBox, TextInput } from './fields';

const NewsletterForm = () => {
  const { toast } = useContext(ToastContext);

  return (
    <div className={`mx-auto max-w-md`}>
      <Formik
        initialValues={{
          firstName: '',
          lastName: '',
          email: '',
          confirmToc: false,
          confirmAge: false,
        }}
        validationSchema={Yup.object({
          firstName: Yup.string(),
          lastName: Yup.string(),
          email: Yup.string().email(t('invalidEmail')).required(t('required')),
          confirmToc: Yup.bool().oneOf([true], t('confirmTocError')),
          confirmAge: Yup.bool().oneOf([true], t('confirmAgeError')),
        })}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          const { hasErrors } = await executeGQL('newsLetterSubscription', {
            firstName: values.firstName,
            lastName: values.lastName,
            email: values.email,
          });
          if (hasErrors) {
            toast.show(t('errorWhileSaving'), {
              type: 'error',
              timeout: 10000,
            });
          } else {
            trackEvent({
              category: 'form',
              action: 'submit',
              label: 'newsletter',
            });
            resetForm({});
            toast.show(t('newsletterThankYou'), {});
          }
          setSubmitting(false);
        }}
      >
        {(props) => (
          <form onSubmit={props.handleSubmit}>
            <TextInput
              name='firstName'
              autoComplete='firstName'
              placeholder={t('firstName')}
              type='text'
              label={t('firstName')}
            />
            <TextInput
              name='lastName'
              autoComplete='lastName'
              placeholder={t('lastName')}
              type='text'
              label={t('lastName')}
            />
            <TextInput
              name='email'
              autoComplete='email'
              placeholder={t('email')}
              type='email'
              label={t('email')}
            />

            <CheckBox
              className='mt-3'
              name='confirmToc'
              label={t('confirmToc')}
            />

            <CheckBox
              name='confirmAge'
              label={t('confirmAge')}
              className='mt-2'
            />

            <Button
              loading={props.isSubmitting}
              type='submit'
              className='mx-auto mt-8 block'
              filled
            >
              {t('login')}
            </Button>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default NewsletterForm;
